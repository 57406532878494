import { makeStyles, Typography } from '@material-ui/core';
import { useUser } from '@hooks/useUser';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px 12px',
    paddingTop: 36,
    textAlign: 'center',
    gap: 16,
    position: 'relative',
    border: `1px solid ${palette.lightGrey.main}`,
    borderRadius: 8,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 19,
    cursor: 'pointer',
  },
  link: {
    color: palette.primary.main,
    '&:visited': {
      color: palette.primary.main,
    },
  },
  qrContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& a': {
      textDecoration: 'none',
      color: palette.primary.main,
      padding: '14px 18px',
      fontWeight: 600,
    },
  },
}));

export const QRBox = () => {
  const { container, link, qrContainer } = useStyles();
  const { data: user } = useUser();
  const { qrImage, qrLink } = { ...user };

  return (
    <div className={container}>
      <Typography variant="h3" className="h3">
        Create an avatar and share it to your dashboard using our scan app
      </Typography>
      <div className={qrContainer}>
        <img
          src={qrImage}
          alt="QR"
          width="100px"
          height="100px"
          loading="lazy"
        />
        <a href={qrLink} target="_blank" rel="noreferrer">
          Go to link
        </a>
      </div>
    </div>
  );
};
